import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import _ from 'lodash';
import React, { useEffect } from "react";
import { displayErrorNotification } from '../../utils/messageServices/notificationService';


export function SchemaCreatorForm(props) {
    const [form] = Form.useForm();
    const fields = props.Data;

    const onConfirm = (values) => {
        //iterate over values and make _.kebabCase on each technicalName
        values.fields.forEach((field) => {
            if (_.snakeCase(field.technicalName) === "task" || _.snakeCase(field.technicalName) === "Task" || _.snakeCase(field.technicalName) === "_id") {
                displayErrorNotification("Der technische Name darf nicht 'task', 'Task' oder '_id' lauten.");
                props.OnClose();
                return;
            };

            field.technicalName = _.toString(field.technicalName);
        });
        props.OnConfirm(values);
        props.OnClose();
    };

    return (
        <Modal
            title="Schema erstellen"
            open={props.Open}
            onOk={onConfirm}
            onCancel={props.OnClose}
            cancelText="Abbrechen"
            width={"50%"}
            footer={[
                <Space>
                    <Button key="back" onClick={props.OnClose}>
                        Abbrechen
                    </Button>
                </Space>]}
        >
            <div style={{
                alignContent: "center",
                justifyContent: "center",
            }}>
                <Form
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    form={form}
                    name="pcoTemplate"
                    autoComplete="off"
                    initialValues={{
                        fields: fields,
                    }}
                    onFinish={onConfirm}
                >
                    <Form.List name="fields">
                        {(fields, { add, remove }) => (
                            <div
                                style={{
                                    display: 'flex',
                                    rowGap: 16,
                                    flexDirection: 'column',
                                }}
                            >
                                {fields.map((field) => (
                                    <Card
                                        size="small"
                                        title={`CSV-Tabellenfeld Nr.: ${field.name + 1}`}
                                        key={field.key}
                                        extra={
                                            <CloseOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        }
                                    >
                                        <Form.Item label="Anzeigename" name={[field.name, 'displayName']}
                                        validateFirst
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                pattern: /^(?!task$)/,
                                                message: 'Der Anzeigename darf nicht "task" lauten.',
                                            },
                                            {
                                                //Groß und kleinschreibung egal für Task
                                                pattern: /^(?!Task$)/,
                                                message: 'Der Anzeigename darf nicht "Task" lauten.',
                                            },
                                            {
                                                pattern: /^(?!_id$)/,
                                                message: 'Der Anzeigename darf nicht "_id" lauten.',
                                            },
                                            {
                                                pattern: /^(?!createdAt$)/,
                                                message: 'Der technische Name darf nicht "createdAt" lauten.',
                                            },
                                        ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item label="Technischer Name" name={[field.name, 'technicalName']}
                                        validateFirst
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie einen technischen Namen ein.',
                                            },
                                            {
                                                pattern: /^[a-z][a-zA-Z0-9_]*$/,
                                                message: 'Der technische Name muss mit einem Kleinbuchstaben beginnen und darf danach Buchstaben, Zahlen und Unterstriche enthalten.',
                                            },
                                            //Auch der nache "task"
                                            {
                                                pattern: /^(?!task$)/,
                                                message: 'Der technische Name darf nicht "task" lauten.',
                                            },
                                            //auch "_id" ist nicht erlaubt
                                            {
                                                pattern: /^(?!_id$)/,
                                                message: 'Der technische Name darf nicht "_id" lauten.',
                                            },
                                            {
                                                pattern: /^(?!createdAt$)/,
                                                message: 'Der technische Name darf nicht "createdAt" lauten.',
                                            },
                                        ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item label="Schlüssel" name={[field.name, 'key']}>
                                            <InputNumber min={1} max={100} />
                                        </Form.Item>

                                        <Form.Item label="Referenzmodell" name={[field.name, 'refModel']}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item label="Datentyp" name={[field.name, 'type']}>
                                            <Select
                                                placeholder="Bitte einen Datentyp wählen"
                                                options={[
                                                    { label: "Zeichenkette", value: "String", key: 1 },
                                                    { label: "Zahl", value: "Number", key: 2 },
                                                    { label: "Ja/Nein", value: "Boolean", key: 3 },
                                                    { label: "Datum", value: "Date", key: 4 },
                                                    { label: "Referenz-Id zu Referenzmodell", value: "ObjectId", key: 5 },
                                                    // { label: "Array [...]", value: "Array", key: 6 },
                                                    // { label: "Objekt {...}", value: "Object", key: 7 }
                                                ]}
                                                optionRender={(option) => {
                                                    return (
                                                        <Space direction='vertical'>
                                                            <div>{option.label}</div>
                                                        </Space>
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </Card>
                                ))}

                                <Button type="dashed" onClick={() => add()} block style={{
                                    marginBottom: 16,
                                }}>
                                    + Feld hinzufügen
                                </Button>
                            </div>
                        )}
                    </Form.List>
                    <Button htmlType="submit" block>
                                    Speichern
                                </Button>
                </Form>
            </div>
        </Modal>
    );
}