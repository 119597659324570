import { Button, Card, Drawer, Form, Input, InputNumber, Result, Space } from "antd";
import React, { useRef } from "react";
import { route_getUserProjects, route_saveUserCostShare } from "../../../routing/v3_Routes/v3_Routes";
import SimpleBackdrop from "../../global/LoadingBackdrop";
import { RedoOutlined } from "@ant-design/icons";
import _ from "lodash";


function ShareConfiguration(props) {

    const [loading, setLoading] = React.useState(false);
    const [initialLoad, setInitialLoad] = React.useState(props?.P || false);
    const [data, setData] = React.useState([{}]);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const form = useRef();

    const submitValues = async (values) => {
        setLoading(true);
        await route_saveUserCostShare(props.User._id, values)
        setLoading(false);
        close();
    };

    const onFinish = (values) => {
        console.log(values);
        submitValues(values);
    };

    const loadData = async () => {
        setLoading(true);
        const result = await route_getUserProjects(props.User._id);
        setData(result);
        setHasLoaded(true);
        setLoading(false);
    };

    const close = () => {
        props.onClose();
        setData([{}]);
        setHasLoaded(false);
    };

    return (
        <>
            <Drawer
                title="Kostenverteilung konfigurieren"
                placement="right"
                closable={true}
                onClose={close}
                open={props.open}
                width={"60%"}
            >

                <Form
                    layout="vertical"
                    name="basic"
                    ref={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={props.onFinishFailed}
                    hidden={(hasLoaded === false) && (initialLoad === true) ? true : false}
                >
                    <Space direction="vertical">
                        {initialLoad ? data?.map((project, index) => {
                            return (
                                <Form.Item
                                    key={index}
                                    label={`Anteil für ${project.title}`}
                                    name={project._id}
                                    dependencies={props.Projects.map((project) => project._id)}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ({ getFieldsValue }) => ({
                                            //Summe aller Felder darf nicht größer als 100 sein
                                            validator(_, value) {
                                                const vV = getFieldsValue()
                                                const sum = Object.values(vV).reduce((a, b) => a + b, 0);
                                                if (sum > 100 || (sum !== 100 && sum !== 0)) {
                                                    return Promise.reject(new Error(`Die Summe aller Felder muss genau 100% sein oder 0% (aktuell: ${sum}%)`));
                                                };
                                                return Promise.resolve();
                                            }
                                        }),
                                    ]}
                                >
                                    <InputNumber min={0} max={100} type="percent" addonAfter="%" />
                                </Form.Item>
                            )
                        }) : props?.Projects?.map((project, index) => {
                            return (
                                <Form.Item
                                    key={index}
                                    label={`Anteil für ${project.title}`}
                                    name={project._id}
                                    dependencies={props?.Projects?.map((project) => project._id)}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ({ getFieldsValue }) => ({
                                            //Summe aller Felder darf nicht größer als 100 sein
                                            validator(_, value) {
                                                const vV = getFieldsValue()
                                                const sum = Object.values(vV).reduce((a, b) => a + b, 0);
                                                if (sum > 100 || (sum !== 100 && sum !== 0)) {
                                                    return Promise.reject(new Error(`Die Summe aller Felder muss genau 100% sein oder 0% (aktuell: ${sum}%)`));
                                                };
                                                return Promise.resolve();
                                            }
                                        }),
                                    ]}
                                >
                                    <InputNumber min={0} max={100} type="percent" addonAfter="%" />
                                </Form.Item>
                            )
                        })}
                    </Space>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Speichern
                        </Button>
                    </Form.Item>
                </Form>

                {(hasLoaded === false) && (initialLoad === true) && <div>
                    <Result
                        title="Bitte laden Sie zuerst die Projekte des Nutzers."
                        subTitle="Da jeder Nutzer unterschiedliche Projekte hat, müssen diese zuerst geladen werden."
                        extra={<Button icon={<RedoOutlined />} type="primary" onClick={() => { loadData()}}>Projekte laden...</Button>}
                    />
                </div>}

            </Drawer>
            <SimpleBackdrop open={loading} />
        </>
    );
};

export { ShareConfiguration };