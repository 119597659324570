import { Button, Card, Col, Descriptions, Divider, Dropdown, Menu, Row, Space, Table, Tag, theme, Typography } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOrders } from "../../../hooks/useOrders";
import { parseOrderStatus } from "../../../utils/helpers/parseOrderStatus.util";
import { CheckOutlined, DeleteFilled, DownOutlined, DownloadOutlined, EditOutlined, MailFilled } from "@ant-design/icons";
import { FileUploaderModal } from "../../modals/fileUpload/FileUploader.modal";
import _ from "lodash";
import PdfPreviewModal from "../../pdfViewer/PdfViwerModal";
import { useAuth } from "../../../firebase";
import { FileIcon } from "@react-pdf-viewer/default-layout";
import { OrderApprovalModal } from "../../modals/order/OrderApproval.modal";
import { v4_cancelOrderById, v4_deleteOrderById, v4_sendOrderConfirmationAgain } from "../../../routing/v4_Routes/order.routes";
import { NewDeliveryModal } from "../../modals/order/Delivery.modal";
import { OrderDocumentsModal } from "./OrderDocuments.modal";
import { displayErrorModal, displaySuccessModal } from "../../../utils/messageServices/modalService";

const { Text } = Typography;

const filterEmptyOrNameless = (array) => {
    return array.filter((item) => item.name && item.name !== "");
};

export function OrderPage() {
    const { orderId } = useParams();
    const { order, getOrder, requestApproval, loading, createOrderConfirmation } = useOrders();
    const { hasPermission } = useAuth();
    const positions = order?.positions ?? [];
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [showDeliveryModal, setShowDeliveryModal] = React.useState(false);
    const [initialDeliveryData, setInitialDeliveryData] = React.useState(null);
    const [publicDocuments, setPublicDocuments] = React.useState([]);

    const navigate = useNavigate();

    const items = [
        {
            key: "1",
            label: "Lieferschein erstellen",
            onClick: () => {
                prepareDelivery();
            },
            disabled: selectedRowKeys.length === 0 || _.includes(["completed", 'completed', 'delivered', 'invoiced', 'cancelled'], order?.status),
        },
    ];

    const onRowSelectionChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const prepareDelivery = () => {
        const selectedPositions = positions.filter(pos => selectedRowKeys.includes(pos._id));
        setInitialDeliveryData(selectedPositions);
        setShowDeliveryModal(true);
    };

    const joinPublicDocsWithDeliveryNotes = () => {
        const docs = _.compact(order?.publicDocuments) ?? [];
        const deliveryNotes = _.compact(order?.deliveryNotes) ?? [];
        let docArray = [];
        if (hasPermission("accounting")) {
            docArray = _.concat(docs, deliveryNotes, order?.orderDocument, order?.orderConfirmationDocument)
            setPublicDocuments(_.compact(docArray));
        } else {
            docArray = _.concat(docs, deliveryNotes)
            setPublicDocuments(_.compact(docArray));
        };

    };

    const COMPLETED_STATES = ["confirmed", "completed", "delivered", "invoiced"];
    const isOrderCompleted = _.includes(COMPLETED_STATES, order?.status);
    const hasAccountingPermission = hasPermission("accounting");

    useEffect(() => {
        getOrder(orderId);
    }, [orderId]);

    useEffect(() => {
        document.title = `Auftrag: ${order?.internalOrderNumber}`;
        joinPublicDocsWithDeliveryNotes();

        return () => {
            document.title = "Assembly";
        };
    }, [order]);

    return (<div style={{ textAlign: "left" }}>
        <div style={{ marginBottom: "1rem" }}>
            <Typography.Title level={5}>
                Kundenauftrag {parseOrderStatus(order?.status)}
            </Typography.Title>
        </div>

        <div>
            <Divider orientation="left" style={{ color: "#333" }}>
                <Typography.Text>Aktionen</Typography.Text>
            </Divider>

            <div>
                <Row gutter={[16, 16]}>
                    <Col>
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => alert("Not implemented yet")}
                            disabled={true}
                            style={{ borderColor: "#0080B6" }}
                        >
                            Details bearbeiten
                        </Button>
                    </Col>

                    <Col>
                        <OrderDocumentsModal
                            orderId={orderId}
                            customButtonProps={{ style: { borderColor: "#0080B6" } }}
                        />
                    </Col>

                    <Col>
                        <Button
                            type="link"
                            icon={<DeleteFilled />}
                            onClick={async () => {
                                try {
                                    await v4_cancelOrderById(orderId);
                                } catch (error) {
                                    console.error(error);
                                    displayErrorModal("Fehler beim Stornieren des Auftrags", error?.message);
                                };
                            }}
                            loading={loading}
                            danger
                            style={{ borderColor: "#0080B6" }}
                            disabled={!hasPermission("accounting") || isOrderCompleted || _.includes(['rejected', "cancelled", "completed", "partiallyDelivered", "delivered"], order?.status)}
                        >
                            Auftrag stornieren
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="link"
                            icon={<DeleteFilled />}
                            onClick={async () => {
                                try {
                                    await v4_deleteOrderById(orderId);
                                    displaySuccessModal("Auftrag gelöscht", "Der Auftrag wurde erfolgreich gelöscht.");
                                } catch (error) {
                                    console.error(error);
                                    displayErrorModal("Fehler beim löschen des Auftrags", error?.message);
                                } finally {
                                    navigate("/orders");
                                }
                            }}
                            danger
                            style={{ borderColor: "#0080B6" }}
                            disabled={!hasPermission("accounting") || isOrderCompleted || !_.includes(['new', 'inApproval'], order?.status)}
                        >
                            Auftrag löschen
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            type="link"
                            icon={<CheckOutlined />}
                            onClick={() => {
                                requestApproval(orderId);
                            }}
                            style={{ fontColor: "#52c41a", borderColor: "#52c41a" }}
                            loading={loading}
                            disabled={(_.has(order, "approval"))}
                        >
                            Freigabe anfordern
                        </Button>
                    </Col>

                    {(order?.status === "inApproval" && _.has(order, "approval")) && (<Col>
                        <OrderApprovalModal
                            buttonDisabled={loading}
                            callback={async () => {
                                try {
                                    getOrder(orderId);
                                } catch (error) {
                                    console.error(error);
                                };
                            }}
                            order={order}
                        />
                    </Col>)}

                    {/* <Col>
                        <Button
                            type="link"
                            icon={<MailFilled />}
                            onClick={() => v4_sendOrderConfirmationAgain(orderId)}
                            style={{ borderColor: "#0080B6" }}
                            disabled={!hasAccountingPermission || !isOrderCompleted || _.includes(["cancelled"], order?.status)}
                        >
                            Auftragsbestätigung erneut versenden
                        </Button>
                    </Col> */}
                </Row>
            </div>
        </div>

        <div style={{ marginBottom: "1rem" }}>
            <div>
                <Divider orientation="left" style={{ color: "#333" }}>
                    <Typography.Text>Auftragsdetails</Typography.Text>
                </Divider>
            </div>
            <Descriptions bordered column={2}>
                <Descriptions.Item label="Kundenauftrag">
                    <Text copyable>{order?.orderNumber}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Auftragsstatus">{parseOrderStatus(order?.status)}</Descriptions.Item>
                <Descriptions.Item label="Wunschliefertermin">Siehe Tabelle</Descriptions.Item>
                {(order?.orderDocument && hasPermission("accounting")) && (<Descriptions.Item label="Kundenbestellung">
                    <PdfPreviewModal
                        pdfUrl={order?.orderDocument?.link}
                        fileName={"Bestellung"}
                        customButtonProps={{ style: { borderColor: "#0080B6" } }}
                    />
                </Descriptions.Item>)}
            </Descriptions>
        </div>

        <div style={{ marginBottom: "1rem" }}>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="hpm Auftrag">
                    <Text copyable>{order?.internalOrderNumber}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Anmerkungen">
                    <Text copyable>{order?.description ?? "Keine Kommentare angefügt."}</Text>
                </Descriptions.Item>
            </Descriptions>
        </div>

        <div>
            <Space>
                {(_.isEmpty(order?.orderDocument)) && <div>
                    <FileUploaderModal
                        showAsCard
                        disabled={!_.isEmpty(order?.orderDocument) || !hasPermission("accounting")}
                        callback={() => {
                            getOrder(orderId);
                        }}
                        modelElementLinkId={order?._id}
                        modelName="Auftrag"
                        targetProperty="orderDocument"
                        _id={order?.project?._id}
                        buttonText="Kundenbestellung hochladen"
                    />
                </div>}
                <div>
                    {!order?.orderConfirmationDocument?.link && (<>
                        {order?.approval && (<>
                            <Button
                                type="link"
                                onClick={async () => {
                                    try {
                                        await createOrderConfirmation(orderId);
                                        await getOrder(orderId);
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                                loading={loading}
                                style={{ borderColor: "#0080B6" }}
                                disabled={!hasPermission("administrator") || !_.includes(["confirmed", 'completed', 'delivered', 'invoiced', 'approvalCompleted'], order?.status)}
                            >
                                Auftragsbestätigung erstellen
                            </Button>
                        </>)}
                    </>)}
                </div>
            </Space>
        </div>

        <div>
            <div>
                <Divider orientation="left" style={{ color: "#333" }}>
                    <Typography.Text>Positionen</Typography.Text>
                </Divider>

                <div>

                    <div style={{ marginBottom: "1rem" }}>
                        {selectedRowKeys?.length > 0 && (
                            <div>
                                <Dropdown menu={{ items }}>
                                    <Button type="link">
                                        Auswahlaktionen <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                    <div>
                        <Table
                            columns={[
                                {
                                    title: "Kundenposition",
                                    dataIndex: "posNumber",
                                },
                                {
                                    title: "Materialnummer",
                                    dataIndex: "matNumber",
                                },
                                {
                                    title: "Bestätigt",
                                    dataIndex: "positionConfirmed",
                                    render: (positionConfirmed, record) => positionConfirmed ? <Tag color="green">Ja</Tag> : <Tag color="red">Nein {record.rejectReason ? "/ Abgelehnt" : ""}</Tag>,
                                },
                                {
                                    title: "Geliefert",
                                    dataIndex: "delivered",
                                    render: delivered => delivered ? <Tag color="green">Ja</Tag> : <Tag color="red">Nein</Tag>,
                                },
                                {
                                    title: "Menge",
                                    dataIndex: "amount",
                                    render: amount => parseFloat(amount).toLocaleString('de-DE'),
                                },
                                {
                                    title: "Preis pro Einheit",
                                    dataIndex: "pricePerUnit",
                                    render: pricePerUnit => hasPermission("accounting") ? parseFloat(pricePerUnit).toLocaleString('de-DE') : "****",
                                },
                                {
                                    title: "Wunschlieferdatum",
                                    dataIndex: "requestedDeliveryDate",
                                    key: "_id",
                                    render: requestedDeliveryDate => <Typography.Text>{new Date(requestedDeliveryDate).toLocaleDateString()}</Typography.Text>,
                                },
                                {
                                    title: "Bestätigter Liefertermin",
                                    dataIndex: "confirmedDeliveryDate",
                                    key: "_id",
                                    render: confirmedDeliveryDate => <Typography.Text>{new Date(confirmedDeliveryDate).toLocaleDateString()}</Typography.Text>,
                                },
                                {
                                    title: "Gelieferte Menge",
                                    dataIndex: "deliveredAmount",
                                    render: deliveredAmount => parseFloat(deliveredAmount ?? 0).toLocaleString('de-DE'),
                                },
                            ]}
                            dataSource={positions}
                            rowKey={record => record._id}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: onRowSelectionChange,
                                getCheckboxProps: record => ({
                                    disabled: record.delivered || !record.positionConfirmed, // Checkbox deaktivieren wenn delivered true ist
                                    name: record._id,
                                }),
                            }}
                        />
                    </div>
                </div>
            </div>

        </div>

        <div style={{ marginBottom: "1rem" }}>
            <div>
                <Divider orientation="left" style={{ color: "#333" }}>
                    <Typography.Text>Auftragsunterlagen</Typography.Text>
                </Divider>
            </div>

            <div>
                <div style={{ marginBottom: "1rem" }}>
                    <Space>
                        <FileUploaderModal
                            showAsCard
                            disabled={_.includes(["completed", "confirmed", 'completed', 'delivered', 'invoiced', 'cancelled'], order?.status) || !hasPermission("accounting")}
                            callback={() => {
                                getOrder(orderId);
                            }}
                            modelElementLinkId={order?._id}
                            modelName="Auftrag"
                            targetProperty="publicDocuments"
                            _id={order?.project?._id}
                            buttonText="Dokumente hochladen (öffentlich)"
                            multiple
                        />
                    </Space>
                </div>
            </div>

            <Table
                columns={[
                    {
                        title: "Name",
                        dataIndex: "name",
                    },
                    {
                        title: "Typ",
                        dataIndex: "docType",
                    },
                    {
                        title: "Hochgeladen",
                        dataIndex: "createdAt",
                        render: (text) => new Date(text).toLocaleDateString(),
                    },
                    {
                        title: "Aktionen",
                        render: (text, record) => (
                            <Space size="middle">
                                <Button
                                    type="link"
                                    icon={<DownloadOutlined />}
                                    onClick={() => window.open(record.link, "_blank")}
                                >
                                    Öffnen
                                </Button>
                            </Space>
                        ),
                    }
                ]}
                dataSource={_.reverse(filterEmptyOrNameless(publicDocuments)) ?? []}
                rowKey={record => record?._id}
                loading={loading}
                footer={() => (
                    <div>
                        <Text type="secondary">Alle für deine Rolle verfügbaren Dokumente</Text>
                    </div>
                )}
            />
        </div>



        <NewDeliveryModal
            open={showDeliveryModal}
            onClose={() => {
                setShowDeliveryModal(false);
                setInitialDeliveryData(null);
            }}
            callback={() => {
                getOrder(orderId);
                setSelectedRowKeys([]);
            }}
            initialDeliveryData={initialDeliveryData}
            order={order}
        />
    </div>)
}