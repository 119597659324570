import { Button, Card, Checkbox, DatePicker, Drawer, Form, Input, InputNumber, Modal, Popconfirm, Select, Table, Typography } from "antd";
import React, { useEffect } from "react";
import { v4_completeApproval } from "../../../routing/v4_Routes/order.routes";
import _ from "lodash";
import datePickerLocale from "../../../locale/datePickerLocale.json";
import dayjs from "dayjs";
import { displayErrorModal } from "../../../utils/messageServices/modalService";


export function OrderApprovalModal({
    showAsCard,
    buttonDisabled,
    callback,
    order
}) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const onSubmit = async () => {
        setLoading(true);
        try {
            console.log("OrderApprovalModal -> order", order);
            if (!order) throw new Error("Es wurde keine Bestellung ausgewählt.");
            if (!order.approval?._id) throw new Error("Es wurde keine Freigabe für diese Bestellung gefunden.");
            const values = await form.validateFields();
            const invalidPositions = values.positions.filter(pos =>
                !pos.positionConfirmed && !pos.rejectReason
            );

            if (invalidPositions.length > 0) {
                // Fehlermeldung zeigen
                invalidPositions.forEach(pos => {
                    form.setFields([
                        {
                            name: ['positions', values.positions.indexOf(pos), 'rejectReason'],
                            errors: ['Bitte wählen Sie einen Grund aus']
                        }
                    ]);
                });
                throw new Error("Bitte geben Sie für alle abgelehnten Positionen einen Grund an.");
            };

            const minOneApproved = values.positions.some((position) => position.positionConfirmed === true);
            console.debug("OrderApprovalModal -> minOneApproved", minOneApproved);
            console.debug("OrderApprovalModal -> values", values);
            await v4_completeApproval(order?._id, order.approval?._id, minOneApproved, values?.comment ?? "", values);
        } catch (error) {
            console.error(error);
            displayErrorModal("Fehler", error?.message ?? "Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
            onCancel();
        };
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onInitialize = () => {
        const positions = order?.positions ?? [];
        const initialPositions = positions?.map((position, index) => {
            return {
                amount: position.amount,
                deliveredAmount: 0,
                delivered: false,
                matNumber: position.matNumber,
                unit: position.unit,
                article: position.article,
                posNumber: position.posNumber,
                pricePerUnit: position.pricePerUnit,
                requestedDeliveryDate: dayjs(position.requestedDeliveryDate),
                deliveredDate: dayjs(position.confirmedDeliveryDate),
                weight: position?.weight || 0,
                pos_id: position._id,
                key: position.posNumber,
                positionConfirmed: false,
                rejectReason: undefined
            };
        });
        if (initialPositions && initialPositions.length > 0 && order) {
            form.setFieldsValue({
                positions: initialPositions
            });
        };
    };

    useEffect(() => {

        return () => { //Handled, when the component is unmounted (closed)
            if (callback && typeof callback === "function") {
                if (open) {
                    callback();
                };
            };
        };
    }, [open]);

    useEffect(() => {
        if (_.has(order, "positions") && order.positions.length > 0) {
            onInitialize();
        };
    }, [order]);

    return (
        <div>
            <div>
                {showAsCard ? (<>
                    <Card
                        style={{ minWidth: 275, alignContent: "center", textAlign: "center" }}
                    >
                        <Button
                            onClick={() => setOpen(true)}
                            type="link"
                            disabled={buttonDisabled}
                            loading={loading}
                        >
                            Auftrag Bestätigen/Ablehnen
                        </Button>
                    </Card>
                </>) : (<>
                    <Button
                        onClick={() => setOpen(true)}
                        type="primary"
                        disabled={buttonDisabled}
                        loading={loading}
                    >
                        Auftrag Bestätigen/Ablehnen
                    </Button>
                </>)}
            </div>

            <Modal
                title={`Auftragsbestätigung zu Kundenbestellung ${order?.orderNumber ?? ""}`}
                open={open}
                onCancel={onCancel}
                destroyOnClose
                width={"80%"}
                centered
                loading={loading}
                footer={[
                    <Popconfirm
                        key="submit"
                        onConfirm={() => onSubmit()}
                        onCancel={onCancel}
                        okText="Dokument erstellen"
                        cancelText="Abberechen"
                        title={<Typography.Text>
                            Sind Sie sicher, dass Sie eine Auftragsbestätigung (Dokument) mit den gewählten Parametern erstellen möchten? Diese Aktion kann nicht rückgängig gemacht werden. <br />Es wird AUTOMATISCH ein Dokument an den Kunden gesendet.
                        </Typography.Text>}
                    >
                        <Button type="primary">Dokument erstellen</Button>
                    </Popconfirm>,
                    <Button
                        key="cancel"
                        onClick={() => setOpen(false)}
                    >
                        Schließen
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        comment: "",
                        approved: false
                    }}
                >
                    <div style={{
                        display: 'flex',
                        rowGap: 16,
                        flexDirection: 'column',
                        // border: '1px solid #d9d9d9',
                        // borderRadius: 15,
                        padding: 16
                    }}>
                        <Form.List name="positions"
                            wrapperCol={{ span: 24 }}
                        >
                            {(fields, { add, remove }) => {
                                const columns = [
                                    {
                                        title: "ID",
                                        dataIndex: "pos_id",
                                        render: (_, __, index) => (
                                            <Form.Item name={[index, "pos_id"]} style={{ margin: 0 }}>
                                                <Input readOnly disabled />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: "Position",
                                        dataIndex: "posNumber",
                                        render: (_, __, index) => (
                                            <Form.Item name={[index, "posNumber"]} style={{ margin: 0 }}>
                                                <InputNumber type="number" readOnly disabled />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: "Materialnummer",
                                        dataIndex: "matNumber",
                                        render: (_, __, index) => (
                                            <Form.Item name={[index, "matNumber"]} style={{ margin: 0 }}>
                                                <Input placeholder="z.B. 0-907-27-782-1" readOnly disabled />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: "Menge",
                                        dataIndex: "amount",
                                        render: (_, __, index) => (
                                            <Form.Item name={[index, "amount"]} style={{ margin: 0 }}>
                                                <InputNumber decimalSeparator="," type="decimal" readOnly disabled />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: "Gewünschter Liefertermin",
                                        dataIndex: "requestedDeliveryDate",
                                        render: (_, __, index) => (
                                            <Form.Item
                                                name={[index, "requestedDeliveryDate"]}
                                                tooltip="Angeforderter Liefertermin"
                                                rules={[{ required: true, message: 'Bitte gebe den Liefertermin ein!' }]}
                                            >
                                                <DatePicker
                                                    locale={datePickerLocale}
                                                    format={"DD.MM.YYYY"}
                                                    showHour
                                                    showMinute
                                                    readOnly
                                                    disabled
                                                />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: "Bestätigter Liefertermin",
                                        dataIndex: "confirmedDeliveryDate",
                                        render: (_, record, index) => (
                                            <Form.Item
                                                name={[index, "confirmedDeliveryDate"]}
                                                tooltip="Bestätigter Liefertermin"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Bitte geben Sie den Liefertermin ein!'
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            const requestedDate = dayjs(order.orderDate);
                                                            if (!(value || requestedDate) && !record.positionConfirmed) {
                                                                return Promise.resolve();
                                                            }

                                                            //Check if position is confirmed. Then its a must field.
                                                            if (record.positionConfirmed && !value) {
                                                                return Promise.reject("Bitte geben Sie den Liefertermin ein!");
                                                            }


                                                            return value.isBefore(requestedDate)
                                                                ? Promise.reject("Der Liefertermin kann nicht vor dem Bestelldatum liegen! - Das ist nicht logisch.")
                                                                : Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <DatePicker
                                                    locale={datePickerLocale}
                                                    format="DD.MM.YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        )
                                    },
                                    {
                                        title: "Position bestätigen",
                                        dataIndex: "positionConfirmed",
                                        render: (_, __, index) => (
                                            <Form.Item name={[index, "positionConfirmed"]} style={{ margin: 0 }} shouldUpdate valuePropName="checked">
                                                <Checkbox />
                                            </Form.Item>
                                        ),
                                    },
                                    {
                                        title: "Grund der Ablehnung",
                                        dataIndex: "rejectReason",
                                        render: (_, record, index) => {
                                            return (
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, currentValues) => {
                                                        return prevValues.positions?.[index]?.positionConfirmed !==
                                                            currentValues.positions?.[index]?.positionConfirmed;
                                                    }}
                                                >
                                                    {({ getFieldValue }) => {
                                                        const positionConfirmed = getFieldValue(['positions', index, 'positionConfirmed']);

                                                        // Nur wenn nicht bestätigt, Ablehnungsgrund anzeigen
                                                        return !positionConfirmed ? (
                                                            <Form.Item
                                                                name={[index, "rejectReason"]}
                                                                style={{ margin: 0 }}
                                                                required={true}
                                                                dependencies={[['positions', index, 'positionConfirmed']]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Bitte wählen Sie einen Grund aus'
                                                                    }
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder="Bitte wählen Sie einen Grund aus"
                                                                    allowClear
                                                                >
                                                                    <Select.Option value="keine Bearbeitung möglich - Fehlteile">
                                                                        Fehlteile
                                                                    </Select.Option>
                                                                    <Select.Option value="keine Bearbeitung möglich - Liefertermin">
                                                                        Liefertermin
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                        ) : null;
                                                    }}
                                                </Form.Item>
                                            );
                                        },
                                    }
                                ];

                                return (
                                    <>
                                        <Table
                                            dataSource={fields}
                                            columns={columns}
                                            rowKey="key"
                                            pagination={false}
                                            scroll={{
                                                x: 1200,
                                                y: 400,
                                            }}
                                            virtual
                                        />
                                    </>
                                );
                            }}
                        </Form.List>
                    </div>
                    <Form.Item
                        label="Kommentar"
                        name="comment"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};