import { Alert, Button, Card, Col, Divider, Row, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { v4_getProjectById } from "../../../../routing/v4_Routes/project.routes";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdate } from "ahooks";
import { TitleImage } from "../PublicProjectPage";
import { motion } from "framer-motion";
import { BookOutlined, BarcodeOutlined, EuroCircleOutlined, ControlOutlined, ConsoleSqlOutlined, DatabaseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateSelectedproject } from "../../../../redux/projectSlicer";
import { EntryMaskDrawer } from "../../../drawers/EntryMaskDrawer";
import { NewVarCostModal } from "../../../modals/costs/NewVarCostModal";
import { displayErrorModal, displayWarningModal } from "../../../../utils/messageServices/modalService";
import _ from "lodash";
import { getUserByFirId, getUsersByFirIds } from "../../../../routing/userManagement";
import { useSelector } from "react-redux";


const { Title, Text } = Typography;

export function ProjectParentPage() {
    const { _id, _ctr } = useParams();
    const update = useUpdate();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [project, setProject] = useState({});
    const [loading, setLoading] = useState(false);
    const [projectUsers, setProjectUsers] = useState([]);
    const [showEntryMaskSettings, setShowEntryMaskSettings] = useState(false);
    const [costModalOpen, setCostModalOpen] = useState(false);

    const currentUser = useSelector((state) => state.userState.firebaseUser);
    const [currentUserRole, setCurrentUserRole] = useState(currentUser?.role || "user");
    const privilegedRoles = ["manager", "projectLead", "hr", "project-assistance"];

    const actions = [
        {
            title: 'Projektmanagement',
            topic: 'Projektmanagement',
            icon: <BookOutlined />,
            onClick: () => {
                navigate(`/manage/projects/${_id}/details`)
            },
        },
        {
            title: 'Projektseite öffnen',
            topic: 'Projektmanagement',
            icon: <BookOutlined />,
            onClick: () => {
                navigate(`/v2/projects/project/${project._id}`);
            },
        },
        {
            title: 'Artikelverwaltung',
            topic: 'Artikelverwaltung',
            icon: <BarcodeOutlined />,
            onClick: () => {
                navigate(`/project/articles/management/v1`);
            },
        },
        {
            title: 'Projektkosten',
            topic: 'Kostenmanagement',
            icon: <EuroCircleOutlined />,
            onClick: () => {
                navigate(`/management/projects/project/${_id}/costs/${_ctr}`);
            },
        },
        {
            title: 'Personalkosten',
            topic: 'Kostenmanagement',
            icon: <EuroCircleOutlined />,
            onClick: () => {
                navigate(`/management/projects/project/${_id}/costs/personal/${_ctr}`);
            },
        },
        {
            title: 'Konfiguration Eingabemaske',
            topic: 'Datenerfassung',
            icon: <ControlOutlined />,
            onClick: () => {
                setShowEntryMaskSettings(true);
            },
        },
        {
            title: 'Datenbankschema',
            topic: 'Schema',
            icon: <ConsoleSqlOutlined />,
            onClick: () => {
                if (project?.csvSchema) {
                    displayWarningModal("CSV Schema", "Sie können das Schema nicht bearbeiten, da es sich um ein CSV Schema handelt. Bitte bearbeiten Sie das Schema über den CSV Schema Editor.");
                } else {
                    navigate(`/manage/projects/${project.projectId}/schema?data=${JSON.stringify({ title: project.title, projectId: project.projectId, mongoId: project._id })}`)
                };
            },
        },
        {
            title: 'CSV Schema bearbeiten',
            topic: 'Schema',
            icon: <ConsoleSqlOutlined />,
            onClick: () => {
                navigate(`/manage/projects/csv/schema/${project._id}`)
            },
        },
        {
            title: 'Einzelkosten buchen',
            topic: 'Kosten',
            icon: <EuroCircleOutlined />,
            onClick: () => {
                setCostModalOpen(true);
            },
        },
        {
            title: 'Datenbank öffnen',
            topic: 'Datenbank',
            icon: <DatabaseOutlined />,
            onClick: () => {
                navigate(`/projects/${project.projectId}/db/${project._id}`);
            },
        },
    ];


    async function fetchProject() {
        setLoading(true);
        const data = await v4_getProjectById(_id, true);
        setProject(data);
        dispatch(updateSelectedproject(data));
        document.title = `Assembly - ${data?.title}`;
        update();
        setLoading(false);
    };

    async function getSetContractDetails() {
        //New Promise, for each user, get the user details
        if (!project) return;
        let users = project?.allowedUserId || [];
        users = _.filter(users, (user) => user.role !== 'administrator');
        if (!projectUsers) return;
        const usersWithDetails = [];
        const uidArray = users?.map((user) => user?.userName);
        const data = await getUsersByFirIds(uidArray);

        data.forEach((user) => {
            const userWithDetails = _.find(users, (u) => u.userName === user?.userName);
            usersWithDetails.push({ ...user, ...userWithDetails });
        });
        setProjectUsers(_.sortBy(usersWithDetails, ['lastName']));
        update();
    };


    useEffect(() => {
        fetchProject();
        setCurrentUserRole(currentUser?.role || "user");

        return () => {
            document.title = "Assembly";
            setProject({});
        };
    }, []);

    useEffect(() => {
        getSetContractDetails();
    }, [project]);

    return (
        <div>
            <div>
                <div style={{ marginBottom: 20 }}>
                    <TitleImage overwrite={true} children={<>
                        <div>
                            <Title level={2} style={{ color: 'white' }}>
                                Projektmanagement - {project?.title} - {_ctr}
                            </Title>
                        </div>
                        <div>
                            <Space direction="horizontal">
                                <Button type="primary" onClick={() => { navigate(`/manage/projects/${_id}/details`) }} loading={loading}>Projektdetails bearbeiten</Button>
                                <Button type="primary" onClick={() => { navigate(`/project/articles/management/v1`); }} loading={loading}>Artikelmanagement</Button>
                            </Space>
                        </div>
                    </>}
                    />


                </div>
            </div>


            <div style={{

            }}>
                <Title style={{ textAlign: "left" }} level={4}> Projektmanagement - Konfiguration</Title>
                <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
                    {
                        actions?.map((act, index) => {
                            const delay = index * 0.1;
 
                            return (

                                <Col span={4}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{
                                            delay: delay,
                                            duration: 0.5,
                                            type: 'spring',
                                            stiffness: 100,
                                            damping: 10,
                                        }}
                                    >
                                        <Card style={{
                                            width: '100%',
                                        }}
                                            key={index}
                                            actions={[
                                                <Button onClick={act.onClick} type="link" loading={loading}>
                                                    {act.title}
                                                </Button>
                                            ]}
                                        >
                                            <Card.Meta
                                                description={act.topic}
                                                avatar={act.icon}
                                            />
                                        </Card>
                                    </motion.div>
                                </Col>

                            )
                        })
                    }
                </Row>
            </div>

            <Divider />

            <div style={{ textAlign: "left" }}>
                <Title level={4}> Weitere Einstellungen</Title>
                <Text type="secondary">
                    Hier können Sie weitere Einstellungen für das Projekt vornehmen. Im Moment sind keine weiteren Einstellmöglichkeiten vorhanden.
                </Text>
            </div>

            <Divider />

            <div style={{ textAlign: "left" }}>
                <Title level={4}>Personen im Team</Title>
                <div>
                    <Text type="secondary">
                        Hier sind alle Personen aufgelistet, die an diesem Projekt beteiligt sind. Sie können hier <strong>keine</strong> Personen hinzufügen oder entfernen.
                        Es werden auch Personen angezeigt, die bereits ausgeschieden sind. Da die Daten auch historisch korrekt sein müssen.
                    </Text>
                </div>

                <div style={{
                    marginTop: 20,
                }}>
                    <Table 
                        rowKey={(record) => record?._id}
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: '_id',
                                render: (text, record) => {
                                    return (
                                        <Text>
                                            {record?.firstName} {record?.lastName}
                                        </Text>
                                    )
                                }
                            }, 
                            {
                                title: 'AÜ',
                                dataIndex: '_id',
                                render: (text, record) => {
                                    return (
                                        <Text>
                                            {record?.contract?.auev ? "AÜ" : "Nein"}
                                        </Text>
                                    )
                                }
                            },
                            {
                                title: 'Vertrg. Nr.',
                                dataIndex: '_id',
                                render: (text, record) => {
                                    return (
                                        <Text>
                                            {record?.contract?.contractNumber || "N/V"}
                                        </Text>
                                    )
                                }
                            }, 
                            {
                                title: 'Vertraglich vereinbarter Lohn (Std./Mtl.)',
                                dataIndex: '_id',
                                render: (text, record) => {
                                    return (
                                        <Text>
                                            {_.includes(privilegedRoles, currentUserRole) ? Number(record?.contract?.contractDetails?.salary?.salaryValue)?.toLocaleString() : "#.###,##"} €
                                        </Text>
                                    )
                                }
                            }, 
                            {
                                title: 'Vertraglich vereinbarte Arbeitszeit',
                                dataIndex: '_id',
                                render: (text, record) => {
                                    return (
                                        <Text>
                                            {_.includes(privilegedRoles, currentUserRole) ? Number(record?.contract?.contractDetails?.workTime?.dailyWorkTime)?.toLocaleString() : "##,#"} h
                                        </Text>
                                    )
                                }
                            }, 
                            {
                                title: 'Rolle',
                                dataIndex: 'role',
                            },
                            {
                                title: 'Status',
                                dataIndex: 'active',
                                render: (text, record) => {
                                    return (
                                        <Text>
                                            {record?.active ? <Tag color="green">aktiv</Tag> : <Tag color="red">inaktiv</Tag>}
                                        </Text>
                                    )
                                }
                            },
                        ]}
                        dataSource={projectUsers || []}
                        pagination={{
                            pageSize: 5,
                        }}
                        locale={{
                            emptyText: 'Keine Daten vorhanden',
                        }}
                        loading={loading}
                    />
                </div>

            </div>
            <EntryMaskDrawer open={showEntryMaskSettings} onClose={() => { setShowEntryMaskSettings(false) }} />
            <NewVarCostModal open={costModalOpen} close={() => setCostModalOpen(false)} _id={project?._id} />
        </div>
    );
};