import { Button, Card, Divider, Space, Table, Tag, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEnvironment } from "../../../hooks/useEnvironment";
import { NewAccountModal } from "../../modals/advancedSettings/Account.modal";
import { NewAddressModal } from "../../modals/advancedSettings/NewAddress.modal";
import { v4_project_deleteAccount, v4_project_deleteAddress, v4_project_deleteNotificationSetting, v4_project_getAccounts, v4_project_getAddresses, v4_project_getNotificationSettings } from "../../../routing/v4_Routes/project.routes";
import { NewNotificationSetting } from "../../modals/advancedSettings/NewNotificationSetting.modal";

const { Title, Text } = Typography;

export function ProjectSettingsDetailPage() {
    const { _id } = useParams();
    const { project, getProject } = useEnvironment();
    const [addresses, setAddresses] = React.useState([]);
    const [accounts, setAccounts] = React.useState([]);
    const [notificationSettings, setNotificationSettings] = React.useState([]);

    const getAddresses = useCallback(async () => {
        if (_id) {
            const addresses = await v4_project_getAddresses(_id);
            setAddresses(addresses);
        };
    }, [_id]);

    const getAccounts = useCallback(async () => {
        if (_id) {
            const accounts = await v4_project_getAccounts(_id);
            setAccounts(accounts);
        };
    }, [_id]);

    const getNotificationSettings = useCallback(async () => {
        if (_id) {
            const notificationSettings = await v4_project_getNotificationSettings(_id);
            setNotificationSettings(notificationSettings);
        };
    }, [_id]);

    useEffect(() => {
        getAddresses();
        getAccounts();
        getProject(_id);
        getNotificationSettings();
    }, [_id]);

    return (
        <div>
            <div>
                <Title level={2}>Erweiterte Einstellungen</Title>
            </div>

            <div style={{ marginBottom: "1rem", textAlign: "left" }}>
            <Space>
                <NewAddressModal
                    showAsCard
                    callback={() => {
                        getAddresses();
                        getProject(_id);
                    }}
                    projectId={_id}
                />
                <NewAccountModal
                    showAsCard
                    callback={() => {
                        getAccounts();
                        getProject(_id);
                    }}
                    projectId={_id}
                />
                <NewNotificationSetting
                    showAsCard
                    callback={() => {
                        getNotificationSettings();
                        getProject(_id);
                    }}
                    projectId={_id}
                    projectData={project}
                />
            </Space>
            </div>


            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333" }}
                >
                    <Text strong>Adressen</Text>
                </Divider>
                <div>
                <Table
                        dataSource={addresses}
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "name",
                                key: "name",
                            },
                            {
                                title: "Straße",
                                dataIndex: "street",
                                key: "street",
                            },
                            {
                                title: "PLZ",
                                dataIndex: "zip",
                                key: "zip",
                            },
                            {
                                title: "Ort",
                                dataIndex: "city",
                                key: "city",
                            },
                            {
                                title: "Notizen",
                                dataIndex: "notes",
                                key: "notes",
                            },
                            {
                                title: "Aktionen",
                                dataIndex: "_id",
                                key: "_id",
                                render: (recordId) => (<Button
                                    onClick={async () => {
                                        try {
                                            await v4_project_deleteAddress(_id, recordId);
                                            await getAddresses();
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }}
                                    danger
                                    type="primary">
                                    Löschen
                                </Button>),
                            }
                        ]}
                        rowKey={(record) => record._id}
                    />
                </div>
            </div>

            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333" }}
                >
                    <Text strong>Accounts/Kontakte</Text>
                </Divider>

                <div>
                <Table
                        dataSource={accounts}
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "name",
                                key: "name",
                            },
                            {
                                title: "Vorname",
                                dataIndex: "firstName",
                                key: "_id",
                            },
                            {
                                title: "Nachname",
                                dataIndex: "lastName",
                                key: "_id",
                            },
                            {
                                title: "E-Mail",
                                dataIndex: "email",
                                key: "email",
                            },
                            {
                                title: "Telefon",
                                dataIndex: "phone",
                                key: "phone",
                            },
                            {
                                title: "Mobil",
                                dataIndex: "mobile",
                                key: "mobile",
                            },
                            {
                                title: "Notizen",
                                dataIndex: "notes",
                                key: "notes",
                            },
                            {
                                title: "Aktionen",
                                dataIndex: "_id",
                                key: "_id",
                                render: (recordId) => (<Button
                                    onClick={async () => {
                                        try {
                                            await v4_project_deleteAccount(_id, recordId);
                                            await getAccounts();
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }}
                                    danger
                                    type="primary">
                                    Löschen
                                </Button>),
                            }
                        ]}
                        rowKey={(record) => record._id}
                    />
                </div>
            </div>



            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333" }}
                >
                    <Text strong>Benachrichtigungseinstellungen (intern)</Text>
                </Divider>

                <div>
                <Table
                        dataSource={notificationSettings}
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "name",
                                key: "name",
                            },
                            {
                                title: "E-Mail",
                                dataIndex: "targetEmail",
                                key: "_id",
                            },
                            {
                                title: "Benachrichtigungen für",
                                dataIndex: "notificationType",
                                key: "_id",
                                render: (tags) => {
                                    tags.map((tag) => {
                                        return (
                                            <Tag color="blue" key={tag}>
                                                {tag}
                                            </Tag>
                                        );
                                    });
                                },
                            },
                            {
                                title: "Erstellt am",
                                dataIndex: "createdAt",
                                key: "_id",
                                render: (date) => {
                                    return new Date(date).toLocaleDateString();
                                },
                            },
                            {
                                title: "Aktionen",
                                dataIndex: "_id",
                                key: "_id",
                                render: (recordId) => (<Button
                                    onClick={async () => {
                                        try {
                                            await v4_project_deleteNotificationSetting(_id, recordId);
                                            await getNotificationSettings();
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }}
                                    danger
                                    type="primary">
                                    Löschen
                                </Button>),
                            }
                        ]}
                        rowKey={(record) => record._id}
                    />
                </div>
            </div>
        </div>
    );
};