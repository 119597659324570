import { Button, Divider, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { NewOrderModal } from "../../modals/order/NewOrder.modal";
import { useOrders } from "../../../hooks/useOrders";
import { OrdersTable } from "../../tables/OrdersTable";
import Search from "antd/es/input/Search";



export function EmployeeOrdersPage() {

    const { loading, refresh, searchOrders, searchResult } = useOrders();
    const [pagination, setPagination] = useState({
        current: 1, //page
        pageSize: 5, //limit
        total: 0, //total
    });

    const search = async (value) => {
        try {
            const sR = await searchOrders(pagination.current, pagination.pageSize, value);
            console.log("searchResult", sR);
            setPagination({ total: searchResult.count, current: pagination.current, pageSize: pagination.pageSize });
        } catch (error) {
            console.error(error);

        }
    };

    const onClear = () => {
        refresh();
    };

    useEffect(() => {
        onClear();
    }, []);

    return (
        <div>
            <div>
                <Typography.Title level={2}>Aufträge</Typography.Title>
            </div>


            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333" }}
                >
                    <Typography.Text>Aufträge</Typography.Text>
                </Divider>

                <div style={{
                    alignContent: "left",
                    textAlign: "left",
                    marginBottom: "1rem",
                }}>
                    <Search
                        placeholder="Suchen..."
                        onSearch={async (value) => search(value)}
                        enterButton="Suchen"
                        onReset={onClear}
                        allowClear
                        size="large"
                    />
                </div>

                <div style={{
                    alignContent: "left",
                    textAlign: "left",
                }}>
                    <OrdersTable
                        data={searchResult?.orders}
                        loading={loading}
                        pagination={{
                            pageSize: pagination.pageSize,
                            current: pagination.current,
                            total: pagination.total,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Aufträgen`,
                            onChange: (page, pageSize) => {
                                setPagination({ ...pagination, current: page, pageSize });
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};