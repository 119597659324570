import { v4_setAPI } from "../api";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";
import { createQueryFromObject } from "../../utils/createQueryFromObject.util";

const API_URL = v4_setAPI();

/**
 * Create a new order
 * @returns {Promise<any>}
 */
export async function v4_createNewOrder(orderData) {
    try {
        const response = await axiosInstance.post(`${API_URL}/orders/new`, orderData);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};


/**
 * Get all orders
 * @returns {Promise<any>}
 */
export async function v4_getAllOrders(page, limit, searchValue) {
    const query = createQueryFromObject({ page, limit, searchValue });
    try {
        const response = await axiosInstance.get(`${API_URL}/orders/orders${query}`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
}


/**
 * Get all orders
 * @returns {Promise<any>}
 */
export async function v4_getOrderById(orderId) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        const response = await axiosInstance.get(`${API_URL}/orders/order/${orderId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};


export async function v4_deleteOrderById(orderId) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        const response = await axiosInstance.delete(`${API_URL}/orders/order/${orderId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
}

export async function v4_cancelOrderById(orderId) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        const response = await axiosInstance.post(`${API_URL}/orders/order/${orderId}/cancelorder`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
}

/**
 * Get all orders
 * @returns {Promise<any>}
 */
export async function v4_requestApproval(orderId) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        const response = await axiosInstance.post(`${API_URL}/orders/order/${orderId}/approvals/new`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};

export async function v4_completeApproval(orderId, approvalId, approvalStatus, comment, body) {
    try {
        console.log("v4_completeApproval -> orderId", orderId, "approvalId", approvalId, "approvalStatus", approvalStatus, "comment", comment);
        if (!orderId || !approvalId) throw new Error("Es wurden nicht alle notwendigen Parameter für ausführung dieser Aktion übergeben.");

        const query = createQueryFromObject({ orderId, approvalId, approvalStatus });

        const response = await axiosInstance.post(`${API_URL}/orders/order/${orderId}/approvals/complete${query}`, { ...body });
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};


///order/:orderId/processes/order-confirmation
export async function v4_requestOrderConfirmation(orderId) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        const response = await axiosInstance.post(`${API_URL}/orders/order/${orderId}/processes/order-confirmation/create`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};


export async function v4_sendOrderConfirmationAgain(orderId) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        await axiosInstance.post(`${API_URL}/orders/order/${orderId}/processes/order-confirmation/send`);
        return;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};

export async function v4_createNewDelivery(orderId, formData) {
    try {
        if (!orderId) throw new Error("Es wurde keine Bestellung ausgewählt.");
        await axiosInstance.post(`${API_URL}/orders/order/${orderId}/processes/delivery/new`, formData);
        return;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
    };
};