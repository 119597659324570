import { Descriptions, Tag, Timeline, Typography } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';
import React from "react";

const { Text } = Typography;

const containerStyle = {
  display: 'flex',
  height: '100vh',
  textAlign: 'left',
};

const leftStyle = {
  flex: '0 0 30%',
  textAlign: 'left',
  marginTop: '50px',
  padding: '10px',
};

const rightStyle = {
  flex: '0 0 70%',
};

export function ChangelogPage() {

    return (
        <div
        style={containerStyle}
        >
          <div style={leftStyle}>
          <Timeline
          >
            <Timeline.Item color="gray" dot={<ClockCircleOutlined style={{ color: 'red'}} />}>Version 1.1.0</Timeline.Item>
            <Timeline.Item color="green">Version 1.0.4 / aktuell / d4b242c66b5245c6af1941f96054e71d2a06603c</Timeline.Item>
            <Timeline.Item>Version 1.0.3 / (veraltet)</Timeline.Item>
            <Timeline.Item>Version 1.0.2 / (veraltet)</Timeline.Item>
          </Timeline>
          </div>

          <div style={rightStyle}>
          <Typography.Title level={5}>
            Versionshinweise
          </Typography.Title>

            <Descriptions column={1} bordered>
              <Descriptions.Item label="Nächste Version">1.1.0</Descriptions.Item>
              {/* Git änderungen */}
              <Descriptions.Item label="Version 1.0.4">
                <ul>
                  <li>Auftragsmodul (Auftragsbestätigungen, Lieferscheine, etc.)<Tag color="blue">Sprint</Tag></li>
                  <li>Datenbank-Transaktionen<Tag color="blue">Sprint</Tag></li>
                  <li>Fehlerbehebungen und Verbesserungen<Tag color="green">abgeschlossen</Tag></li>
                </ul>
              </Descriptions.Item>
            </Descriptions>
          </div>
          </div>
    );
    };