import { Button, Divider, Space, Table, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { parseOrderStatus } from "../../utils/helpers/parseOrderStatus.util";
import { useNavigate } from "react-router-dom";
import { ExportOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { OrderDocumentsModal } from "../pages/assingments/OrderDocuments.modal";
import { useAuth } from "../../firebase";

export function OrdersTable({ data, loading, pagination }) {
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    const columns = [
        {
            title: "",
            dataIndex: "_id",
            key: "_id",
            render: id => <Space>
                <Tooltip title={id}>
                    <Button type="link" size="small"
                        onClick={() => navigate(`/orders/${id}`)}
                        icon={<ExportOutlined />}
                    >Auftrag</Button>
                </Tooltip>
                <Divider type="vertical" />
                <OrderDocumentsModal
                    orderId={id}
                />
            </Space>
        },
        {
            title: "Auftragsinformationen",
            children: [
                {
                    title: "Auftragsstatus",
                    dataIndex: "status",
                    key: "_id",
                    render: status => parseOrderStatus(status),
                },
                {
                    title: "Auftragsnummer",
                    dataIndex: "orderNumber",
                    key: "_id",
                    render: orderNumber => <Typography.Text>{orderNumber}</Typography.Text>,
                    width: 250
                },
                {
                    title: "Bestelldatum",
                    dataIndex: "orderDate",
                    key: "_id",
                    render: date => <Typography.Text>{new Date(date).toLocaleDateString()}</Typography.Text>,
                },
                // {
                //     title: "Bestätigtes Lieferdatum",
                //     dataIndex: "confirmedDeliveryDate",
                //     key: "_id",
                //     render: confirmedDeliveryDate => <Typography.Text>{confirmedDeliveryDate ? new Date(confirmedDeliveryDate)?.toLocaleDateString() : "Nicht bestätigt"}</Typography.Text>,
                // },
                {
                    title: "Positionen",
                    dataIndex: "positions",
                    key: "_id",
                    render: positions => <Typography.Text>{Number(positions?.length ?? '?').toLocaleString('de-DE')} Pos.</Typography.Text>,
                },
            ],
        },
        {
            title: "Interne Informationen",
            children: [
                {
                    title: "Projekt",
                    dataIndex: "project",
                    key: "_id",
                    render: project => <Typography.Text>{project?.title}</Typography.Text>,
                },
                {
                    title: "Kunde",
                    dataIndex: "project",
                    key: "_id",
                    render: project => <Typography.Text>{project?.projectLocation?.companyName}</Typography.Text>,
                },
                {
                    title: "Erstellt",
                    dataIndex: "createdAt",
                    key: "_id",
                    render: date => <Typography.Text>{new Date(date).toLocaleDateString()}</Typography.Text>,
                },
            ]
        },
        // {
        //     title: "Aktionen",
        //     key: "_id",
        //     render: (_, record) => {
        //         return null;
        //     }
        // }
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            bordered
            virtual
            pagination={pagination}
            // scroll={{ x: 1300, y: 500 }}
            rowKey={record => record._id}
            expandable={{
                rowExpandable: record => (record?.positions?.length ?? 0) > 0,
                expandedRowRender: record => {
                    const positions = record.positions;
                    return (
                        <Table
                            columns={[
                                {
                                    title: "Kundenposition",
                                    dataIndex: "posNumber",
                                },
                                {
                                    title: "Materialnummer",
                                    dataIndex: "matNumber",
                                },
                                {
                                    title: "Menge",
                                    dataIndex: "amount",
                                    render: amount => parseFloat(amount).toLocaleString('de-DE'),
                                },
                                {
                                    title: "Preis pro Einheit",
                                    dataIndex: "pricePerUnit",
                                    render: pricePerUnit => hasPermission("accounting") ? parseFloat(pricePerUnit).toLocaleString('de-DE') : "****",
                                },
                                {
                                    title: "Wunschlieferdatum",
                                    dataIndex: "requestedDeliveryDate",
                                    key: "_id",
                                    render: requestedDeliveryDate => <Typography.Text>{new Date(requestedDeliveryDate).toLocaleDateString()}</Typography.Text>,
                                },
                                {
                                    title: "Bestätigter Liefertermin",
                                    dataIndex: "confirmedDeliveryDate",
                                    key: "_id",
                                    render: confirmedDeliveryDate => <Typography.Text>{new Date(confirmedDeliveryDate).toLocaleDateString()}</Typography.Text>,
                                },
                                {
                                    title: "Geliefert",
                                    dataIndex: "delivered",
                                    render: delivered => delivered ? <Tag color="green">Ja</Tag> : <Tag color="red">Nein</Tag>,
                                },
                                {
                                    title: "Gelieferte Menge",
                                    dataIndex: "deliveredAmount",
                                    render: deliveredAmount => parseFloat(deliveredAmount ?? 0).toLocaleString('de-DE'),
                                },
                            ]}
                            dataSource={positions}
                            rowKey={record => record._id}
                        />
                    )
                },
            }}
        />
    );
};