import React from "react";
import { Button, Card, Tag, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { ShareConfiguration } from "../drawers/user/ShareConfiguration";
import { route_getUserProjects } from "../../routing/v3_Routes/v3_Routes";
import { displayErrorNotification } from "../../utils/messageServices/notificationService";


function TeamUserCard(props) {

    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [userProjects, setUserProjects] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const onDrawerClose = () => {
        setOpen(false);
    };

    const getUsersProjects = async (user) => {
        setLoading(true);
        if (!user) {
            displayErrorNotification("Wir haben hier wohl einen leeren Datensatz erwischt. Bitte versuchen Sie es erneut. 😅");
            setLoading(false);
            return;
        };

        const project = await route_getUserProjects(user?._id);
        if (project) {
            setUserProjects(project);
        };
        setSelectedUser(user);
        setLoading(false);
        setOpen(true);
    };

    return (
        <>
            <div>
                <Card
                    extra={<Button size="small" type="primary"
                        onClick={() => { getUsersProjects(props?.User); console.log(selectedUser); }}
                        loading={loading}
                    >Kostenverteilung festlegen</Button>}
                >
                    <Meta
                        title={props?.FirstName + " " + props?.LastName}
                        description={props?.UserEmail}
                    />
                <div style={{
                    marginTop: "10px"
                }}>
                    {props?.User?.projectSettings?.length > 0 ? (
                        <Tag color="green">Kostenverteilung festgelegt</Tag>
                    ) : (
                           <Tooltip title="Der User wird auf allen Projekten standardmäßig mit 100% seiner Kosten gebucht. Was in der Gesamtbetrachtung zur Mehrbelastung führen kann.">
                           <Tag color="red">Kostenverteilung nicht festgelegt</Tag>
                           </Tooltip>
                    )
                        }
                </div>
                </Card>
                <ShareConfiguration open={open} onClose={onDrawerClose} User={selectedUser} Projects={userProjects} />
            </div>
        </>
    );
};

export default TeamUserCard;