import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Modal, Select, Button, Input, Form, message } from "antd";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Option } from "antd/es/mentions";
import { addUserToProject, getAllUsers } from "../../../routing/crudActions";
import _ from "lodash";
import { invokeSessionWithProject } from "../../../config/session/session";


const globalSize = "large";


function AddExistingUserDialog(props, { onFinally }) {

    const project = useSelector((state) => state.projectState.selectedProject);
    const users = props?.Users;
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [dialog, setDialog] = useState(false);

    const [messageApi, contextHolder] = message.useMessage()

    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMess = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const handleCancel = useCallback(() => {
        props.setOpen(false);
    }, [props]);

    const MenuProps = useMemo(() => ({
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    }), []);

    const processUserCreation = useCallback(async () => {

        setIsLoading(true);

        await addUserToProject(project._id, user._id)
            .then(result => {
                setDialog(false);
                success("Nutzer*in erfolgreich erstellt.", result.data);
            })
            .catch(error => {
                console.log(error);
                errorMess("Ein Fehler ist aufgetreten.", error);
            })
            .finally(async () => {
                await invokeSessionWithProject(project)
                setIsLoading(false);
                handleCancel();
            })
    }, [project, user, handleCancel]);


    const [formData, setFormData] = useState({});

    const handleSetFormData = useCallback((e) => {
        console.log(e.target);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.id]: e.target.value,
        }));
        console.log(formData);
    }, []);

    const userOptions = useMemo(() => users?.map((user) => ({
        value: user?._id,
        label: `${user?.firstName} ${user?.lastName}`,
        data: user
    })), [users]);

    return (
        <>
            {contextHolder}
            <div>
                <Modal
                    open={props.OpenDialog}
                    onCancel={handleCancel}
                    style={{
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center"
                    }}
                    footer={
                        [
                            <Button key="submit" type="primary" onClick={processUserCreation} loading={isLoading}>
                                Hinzufügen
                            </Button>
                        ]
                    }>
                    <Box sx={{ mb: 5 }}>
                        <h1>Teammitglied hinzufügen</h1>
                        <Typography variant="caption2" sx={{ px: 5, mt: 1, mb: 5 }}>
                            Bitte wähle einen Nutzer aus.
                        </Typography>
                    </Box>

                    <Form
                        name="userDetails"
                        labelCol={{
                            span: 5,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        onChange={handleSetFormData}
                    >

                        <Form.Item
                            label="Nutzer"
                            name="selected_user"
                            rules={[
                                {
                                    required: true,
                                    message: 'Bitte wähle mindestens ein Projekt!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Nutzer auswählen"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                size={globalSize}
                                value={user || undefined}
                                onChange={(e, o) => { console.log(o.data); setUser(o.data) }}
                                style={{
                                    width: 320,
                                }}
                                // mode="multiple"
                                loading={isLoading}
                                options={userOptions}
                            />
                        </Form.Item>

                    </Form>
                </Modal>
            </div>
        </>
    );
};

export default React.memo(AddExistingUserDialog);