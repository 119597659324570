import { Button, Checkbox, DatePicker, Descriptions, Divider, Form, Input, InputNumber, Select, Space, Table, Typography } from "antd";
import React, { useEffect } from "react";
import datePickerLocale from "../../../locale/datePickerLocale.json";
import dayjs from "dayjs";

export function CreateDeliveryForm({ form, initialData, order }) {

    const onInitialize = () => {
        const positions = initialData;
        const initialPositions = positions?.map((position, index) => {
            return {
                amount: position.amount,
                deliveredAmount: position.amount - position.deliveredAmount,
                delivered: true,
                matNumber: position.matNumber,
                unit: position.unit,
                article: position.article,
                posNumber: position.posNumber,
                pricePerUnit: position.pricePerUnit,
                requestedDeliveryDate: dayjs(position.requestedDeliveryDate),
                deliveredDate: dayjs(), //position.confirmedDeliveryDate
                weight: position?.weight || 0,
                pos_id: position._id,
                key: position.posNumber,
            };
        });
        if (initialPositions && initialPositions.length > 0 && order) {
            form.setFieldsValue({
                positions: initialPositions
            });
        };

        if (order) {
            form.setFieldsValue({
                orderNumber: order.orderNumber,
                project: order.project?._id,
                internalOrderNumber: order.internalOrderNumber,
                deliveryAddress: order.deliveryAddress._id,
                invoiceAddress: order.invoiceAddress._id,
                comments: order.comments,
                //Erstelle eine Kommentar, dass es sich um eine Teil-Lieferung handelt wenn die Lieferung nicht vollständig ist
                deliveryComments: order.positions?.length !== initialPositions.length ? `Teil-Lieferung: ${initialPositions.length} von ursprünglich ${order.positions?.length} Positionen. \n` : "",
            });
        };
    };


    useEffect(() => {
        if (initialData) {
            onInitialize();
        };
    }, [initialData]);

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name="newDelivery"
                initialValues={{ remember: true }}
            >

                <div style={{
                    marginBottom: 16
                }}>
                    <Descriptions column={1} title={order?.deliveryAddress?.addressType} bordered>
                        <Descriptions.Item label="Name"><Typography.Text type="primary">{order?.deliveryAddress?.name}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label="Straße">{order?.deliveryAddress?.street}</Descriptions.Item>
                        <Descriptions.Item label="Adresszusatz">{order?.deliveryAddress?.addressLine2}</Descriptions.Item>
                        <Descriptions.Item label="PLZ/Ort">{order?.deliveryAddress?.zip} {order?.deliveryAddress?.city}</Descriptions.Item>
                        <Descriptions.Item label="Land">{order?.deliveryAddress?.country}</Descriptions.Item>
                    </Descriptions>
                </div>

                <Space>
                    <Form.Item
                        label="Kundenauftragsnummer"
                        name="orderNumber"
                        rules={[{ required: true, message: 'Ohne geht das nicht...' }]}
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Auftragsnummer"
                        name="internalOrderNumber"
                        rules={[{ required: true, message: 'Ohne geht das nicht...' }]}
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Projekt"
                        name="project"
                        rules={[{ required: true, message: 'Ohne geht das nicht...' }]}
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                    label="Lieferadresse"
                    name="deliveryAddress"
                    rules={[{ required: true, message: 'Bitte die Lieferadresse angeben!' }]}
                >
                    <Input readOnly />
                </Form.Item>

                <Form.Item
                    label="Rechnungsadresse"
                    name="invoiceAddress"
                    rules={[{ required: true, message: 'Bitte die Lieferadresse angeben!' }]}
                >
                    <Input readOnly />
                </Form.Item>
                </Space>

                <Form.Item
                    label="Anmerkungen zum Auftrag"
                    name="comments"
                    tooltip="Beschreibung des Auftrags"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea placeholder="--" readOnly />
                </Form.Item>

                <Form.Item
                    label="Anmerkungen (Wird auf den Lieferschein gedruckt)"
                    name="deliveryComments"
                    tooltip="Beschreibung des Auftrags"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea placeholder="z.B. " />
                </Form.Item>

                <Form.Item>
                    <Divider
                        orientation="left"
                    >
                        Positionen
                    </Divider>
                </Form.Item>

                <div style={{
                    display: 'flex',
                    rowGap: 16,
                    flexDirection: 'column',
                    // border: '1px solid #d9d9d9',
                    // borderRadius: 15,
                    padding: 16
                }}>
                    <Form.List name="positions"
                        wrapperCol={{ span: 24 }}
                    >
                        {(fields, { add, remove }) => {
                            const columns = [
                                {
                                    title: "Position",
                                    dataIndex: "posNumber",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "posNumber"]} style={{ margin: 0 }} required>
                                            <InputNumber type="number" readOnly />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Materialnummer",
                                    dataIndex: "matNumber",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "matNumber"]} style={{ margin: 0 }} required>
                                            <Input placeholder="z.B. 0-907-27-782-1" />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Menge",
                                    dataIndex: "amount",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "amount"]} style={{ margin: 0 }} required>
                                            <InputNumber decimalSeparator="," type="decimal" readOnly />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Preis",
                                    dataIndex: "pricePerUnit",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "pricePerUnit"]} style={{ margin: 0 }}>
                                            <InputNumber decimalSeparator="," type="decimal" readOnly style={{ display: 'none'}} />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Liefermenge",
                                    dataIndex: "deliveredAmount",
                                    render: (_, __, index) => (
                                        //Liefermenge kann nicht größer als die bestellte Menge sein
                                        <Form.Item name={[index, "deliveredAmount"]} style={{ margin: 0 }}
                                        rules={[{
                                            required: true,
                                            message: 'Bitte geben Sie eine Liefermenge an!',
                                            validator: async (_, value) => {
                                                if (value <= form.getFieldValue(["positions", index, "amount"])) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Die Liefermenge kann nicht größer als die bestellte Menge sein!'));
                                            },
                                            validateTrigger: 'onBlur',
                                        }]}
                                        required>
                                            <InputNumber decimalSeparator="," type="decimal" />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Einheit",
                                    dataIndex: "unit",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "unit"]} style={{ margin: 0 }} required>
                                            <Select
                                                placeholder="Einheit auswählen"
                                                disabled
                                                allowClear
                                                options={[
                                                    { label: "Stück", value: "Stück" },
                                                    { label: "Kilogramm", value: "Kilogramm" },
                                                    { label: "Meter", value: "Meter" },
                                                    { label: "Liter", value: "Liter" },
                                                    { label: "Kubikmeter", value: "Kubikmeter" },
                                                    { label: "Palette", value: "Palette" },
                                                ]}
                                            />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Positionsgewicht",
                                    dataIndex: "weight",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "weight"]} style={{ margin: 0 }}
                                        //Wert muss größer 0 sein
                                        rules={[{ 
                                            required: true,
                                            message: 'Bitte geben Sie ein Gewicht an!',
                                            validator: async (_, value) => {
                                                if (value > 0) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Das Gewicht muss größer gleich 0 sein!'));
                                            },
                                            validateTrigger: 'onBlur'
                                            }]}
                                        >
                                            <InputNumber decimalSeparator="," type="decimal" suffix={"Kg"} />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Artikel",
                                    dataIndex: "article",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "article"]} style={{ margin: 0 }} required>
                                            <Input
                                                readOnly
                                                placeholder="Ausgewählter Artikel"
                                            />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Kommentar",
                                    dataIndex: "description",
                                    render: (_, __, index) => (
                                        <Form.Item name={[index, "description"]} style={{ margin: 0 }} required>
                                            <Input
                                                placeholder=""
                                            />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Geliefert",
                                    dataIndex: "delivered",
                                    render: (_, __, index) => (
                                        <Form.Item
                                            name={[index, "delivered"]}
                                            valuePropName="checked"
                                            style={{ margin: 0 }}
                                            initialValue={false}
                                        >
                                            <Checkbox disabled>
                                                Position liefern
                                            </Checkbox>
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Liefertermin",
                                    dataIndex: "deliveredDate",
                                    render: (_, __, index) => (
                                        <Form.Item
                                            name={[index, "deliveredDate"]}
                                            style={{ margin: 0 }}
                                            initialValue={false}
                                            
                                        >
                                            <DatePicker
                                                locale={datePickerLocale}
                                                format="DD.MM.YYYY"
                                                style={{ width: "100%" }}
                                                minDate={dayjs()}
                                                maxDate={dayjs()}
                                                disabled
                                            />
                                        </Form.Item>
                                    ),
                                },
                                {
                                    title: "Aktion",
                                    dataIndex: "operation",
                                    render: (_, __, index) => (
                                        <Button danger onClick={() => remove(index)}>
                                            Entfernen
                                        </Button>
                                    ),
                                },
                            ];

                            return (
                                <>
                                    <Table
                                        dataSource={fields}
                                        columns={columns}
                                        rowKey="key"
                                        pagination={false}
                                    />
                                    <div>
                                        <Typography.Text type="secondary">
                                            Weitere Positionen können über die Hauptmaske hinzugefügt werden.
                                        </Typography.Text>
                                    </div>
                                </>
                            );
                        }}
                    </Form.List>
                </div>

            </Form>
        </>
    );
};