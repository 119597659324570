import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4_getProjectById } from "../../../routing/v4_Routes/project.routes";
import { displayWarningNotification } from "../../../utils/messageServices/notificationService";
import { addListenerTo } from "../../../listener/firebaseSnapshotListener";
import { Alert, Button, Card, Descriptions, Divider, Flex, Space, Switch, Typography } from "antd";
import { BaseProjectDataForm } from "../../forms/projectDetails/BaseProjectDataForm";
import { ReAuthenticationFormModal } from "../../security/ReAuthenticationForm.modal";
import { p_projectPropChange } from "../../../utils/propertyChanges/handlePropertyChange.util";
import { displayInfoModal, displaySuccessModal } from "../../../utils/messageServices/modalService";
import { ValueConverter } from "../../../utils/valueConverters";
import { useUpdate } from "ahooks";
import { useSelector } from "react-redux";
import { useAuth } from "../../../firebase";




export function MainProject() {

    //Params
    const { _id } = useParams();
    const { hasPermission } = useAuth();
    const disabled = !hasPermission("project-assistance");

    //States
    const [project, setProject] = useState(null);
    const [triggerAuthModal, setTriggerAuthModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeValue, setChangeValue] = useState(null);

    //Hooks
    const update = useUpdate();
    const navigate = useNavigate();

    //Functions
    const getProject = async () => {
        if (!_id) displayWarningNotification("Anscheinend hat etwas nicht geklappt. Bitte versuchen Sie es erneut.");
        const data = await v4_getProjectById(_id);
        setProject(data);
        update();
    };

    const updateProject = async () => {
        setLoading(true);
        if (!changeValue) {
            displayWarningNotification("Es wurden keine Änderungen vorgenommen.");
            setLoading(false);
            return;
        };
        p_projectPropChange(changeValue.value, changeValue.fieldName, project, async () => {
            await getProject();
        });
        setLoading(false);
        
    };

    const onChange = (value, fieldName, type) => {
        setChangeValue({
            value: ValueConverter(value, type),
            fieldName: fieldName
        });
        setTriggerAuthModal(true);
    };

    //Effects
    useEffect(() => {
        getProject();
        const unsubscribe = addListenerTo(_id ?? "projects", async (snapshot) => {
            console.log(snapshot);
            getProject();
        });

        return () => {
            setProject(null);
            unsubscribe();
        };
    }, [_id]);


    return (
        <div>
            <div>
                <Flex gap="large" align="center" justify="center" horizontal>
                    <div>
                    <img src={project?.imageUrl} alt="Project_Logo_Image" width="100px"  style={{ resize: 'both' }} className="ButtonIcons" />
                    </div>
                    <Flex gap="small" align="center" justify="center" vertical>
                        <Typography.Title level={3}>{project?.title}</Typography.Title>
                        <Typography.Text copyable>{project?._id}</Typography.Text>
                    </Flex>
                </Flex>
            </div>

            <div style={{
                alignContent: "left",
                textAlign: "left",
            }}>
                <div>
                    <Divider orientation="left">
                        Erweiterte Einstellungen
                    </Divider>
                </div>

                <div>
                    <Space>
                        <Card>
                            <Button
                                type="link"
                                onClick={() => {
                                    navigate(`/p/${_id}/details/setting/advanced`);
                                }}
                                loading={loading}
                                disabled={disabled}
                            >
                                Erweiterte Einstellungen
                            </Button>
                        </Card>
                    </Space>
                </div>
            </div>

            <div>
                <div>
                    <Divider orientation="left">
                        Einstellungen für die allgemeine Datenerfassung
                    </Divider>
                </div>
                <div>
                    <Descriptions column={1} bordered style={{ textAlign: "left"}}>
                        <Descriptions.Item label="Stillstandstzeiten Erfassen" span={24}>
                            <Space direction="vertical">
                                <Switch size="large" checkedChildren="Ja" unCheckedChildren="Nein" value={project?.logDowntimes ?? false}
                                    onChange={(checked) => onChange(checked, "logDowntimes", "boolean")}
                                    defaultChecked={project?.logDowntimes ?? false}
                                    defaultValue={project?.logDowntimes ?? false}
                                    loading={loading}
                                    disabled={disabled}
                                />
                                <Alert message="Beim deaktivieren werden alle erfassten Stillstände gelöscht!" type="warning" showIcon />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Nicht erkannte Artikel in separate Datenbank Speichern?" span={24}>
                        <Space direction="vertical">
                                <Switch size="large" checkedChildren="Ja" unCheckedChildren="Nein" value={project?.logMissedArticles ?? false} 
                                    onChange={(checked) => onChange(checked, "logMissedArticles", "boolean")}
                                    // onChange={(checked) => console.log(checked)}
                                    defaultChecked={project?.logMissedArticles ?? false}
                                    defaultValue={project?.logMissedArticles ?? false}
                                    loading={loading}
                                    disabled={disabled}
                                />
                                <Alert message="Beim deaktivieren werden alle Buchungen in der Ersatzdatenbank gelöscht!" type="warning" showIcon />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Datensatzimport für große Dateien erlauben?" span={24}>
                        <Space direction="vertical">
                                <Switch size="large" checkedChildren="Ja" unCheckedChildren="Nein" value={project?.largeCsv ?? false} 
                                    onChange={(checked) => onChange(checked, "largeCsv", "boolean")}
                                    // onChange={(checked) => console.log(checked)}
                                    defaultChecked={project?.largeCsv ?? false}
                                    defaultValue={project?.largeCsv ?? false}
                                    loading={loading}
                                    disabled={disabled || project?.dataMode !== "csv"}
                                />
                                <Alert message="Uploads werden ohne Gegenprüfung durchgeführt, direktimport. (standard bei mehr als 500 CSV Zeilen)" type="info" showIcon />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Negative Zahlen umwandeln?" span={24}>
                        <Space direction="vertical">
                                <Switch size="large" checkedChildren="Ja" unCheckedChildren="Nein" value={project?.turnNegativeValuesPositive ?? false} 
                                    onChange={(checked) => onChange(checked, "turnNegativeValuesPositive", "boolean")}
                                    // onChange={(checked) => console.log(checked)}
                                    defaultChecked={project?.largeCsv ?? false}
                                    defaultValue={project?.largeCsv ?? false}
                                    loading={loading}
                                    disabled={disabled || project?.dataMode !== "csv"}
                                />
                                <Alert message="Wenn diese Option aktiviert ist, werden alle negativen Zahlen bei Datenerfassung, automatisch in positive umgewandelt. (Ab Umstellungszeitpunkt)" type="info" showIcon />
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>

            <div>
                <div>
                    <Divider orientation="left">
                        Stammdaten
                    </Divider>
                </div>
                <div>
                    <BaseProjectDataForm project={project} />
                </div>
            </div>
            
            <ReAuthenticationFormModal
                Open={triggerAuthModal}
                OnCancel={() => { 
                    displayInfoModal("Vorgang abgebrochen", "Der Vorgang wurde abgebrochen. Es wurden keine Änderungen vorgenommen.");
                    setTriggerAuthModal(false);
                    setChangeValue(null);
                }}
                OnSuccess={() => { 
                    setTriggerAuthModal(false);
                    updateProject();
                }}
            />
        </div>
    );
};