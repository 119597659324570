import { v4_setAPI } from "../api";
import { displayInfoNotification, displaySuccessNotification } from "../../utils/messageServices/notificationService";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";
import { createQueryFromObject } from "../../utils/createQueryFromObject.util";

const API_URL = v4_setAPI();


export async function v4_get_databaseEntriesWithPagination(project_id, page, limit, searchValue) {
  try {
    const query = createQueryFromObject({ project_id, page, limit, searchValue });
    //API beispeil: GET /database/read/:project_id?page=1&limit=10
    const data =  await axiosInstance.get(`${API_URL}/database/read/${project_id}${query}`);
    displayInfoNotification(`Abfrageleistung ${Number(data?.data?.unfilteredCount ?? 0).toLocaleString('de-DE', { minimumFractionDigits: 0 })} Dokumente in ${Number(data?.data?.queryTimeInSeconds ?? 0).toLocaleString('de-DE', { minimumFractionDigits: 5 })} Sekunden mit ${Number(data?.data?.totalData ?? 0).toLocaleString('de-DE', { minimumFractionDigits: 0 })} Ergebnissen abgeschlossen`);
    return data.data;
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
  };
};